import { Layout, Typography, theme } from 'antd';
import { Content, Footer, Header } from 'antd/es/layout/layout';
import TopNavbar from '../components/TopNavbar';
import SideNavbar from '../components/SideNavbar';
import DashboardStats from '../components/DashboardStats';
import PageFooter from '../components/PageFooter';
import WeeklyApplicationGraph from '../components/WeeklyApplicationGraph';
import { useEffect, useState } from 'react';
import { base_url } from '../data_types/Constants';


const Dashboard = () => {
    var [ data, setData ] = useState([{}]);
    const [weeklyApplicationCount, setWeeklyApplicationCount] = useState(0);


    useEffect(() => {
        async function getWeeklyCount() {
            try {
                const res = await fetch(
                    `${base_url}/applications/weekly_count`,
                    {
                        method: "GET",
                        credentials: "include"
                    }
                )
                var weekData = await res.json();
                weekData = weekData.count;
                setData(weekData);
                var totalCount = 0;
                weekData.forEach((element: any) => {
                    totalCount += element.Applications;
                });

                setWeeklyApplicationCount(totalCount);
            } catch(err) {
                console.log("err");
            }
        }

        getWeeklyCount();
    }, []);


    return (
        <Layout>
            <TopNavbar />
            <Content>
                <Layout hasSider >
                    <SideNavbar />
                    <Content style={{ padding: '0 24px', minHeight: '100vh', backgroundColor: '#FFFFFF' }}>
                        <Typography.Title level={1} style={{ paddingTop: '4px', marginTop: '4px', marginBottom: '4px'}}>
                            trackr
                        </Typography.Title>

                        <Typography.Title level={5} style={{marginTop: '4px', marginBottom: '8px'}}>
                            here you can see some statistics about your applications
                        </Typography.Title>

                        <DashboardStats weeklyApplicationCount={weeklyApplicationCount} />

                        <WeeklyApplicationGraph data={ data } />

                    </Content>
                </Layout>
            </Content>
            <PageFooter />
        </Layout>
    );
}

export default Dashboard