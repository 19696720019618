import { App, Card, Col, Layout, Modal, Row, Space, Table, Tag, Typography, theme } from 'antd';
import { Content, Footer, Header } from 'antd/es/layout/layout';
import TopNavbar from '../components/TopNavbar';
import SideNavbar from '../components/SideNavbar';
import RecentApplicationsCard from '../components/RecentApplicationsCard';
import ApplicationsTable from '../components/ApplicationsTable';
import PageFooter from '../components/PageFooter';
import { useEffect, useState } from 'react';
import { base_url } from '../data_types/Constants';

const Applications = () => {
    const [loading, setLoading] = useState(true);
    const [tableData, setTableData] = useState([]);

    useEffect(() => {
        async function getApplicationData() {
            try {
                const res = await fetch(
                    `${base_url}/applications/all`,
                    {
                        method: "GET",
                        credentials: "include"
                    }
                )
    
                const data = await res.json();
                if (res.ok) {
                    // data.applications.forEach((object: any) => {
                    //     object.dateApplied = Date.parse(object.dateApplied);
                    // })

                    console.log(data.applications);
                    setLoading(false);
                    setTableData(data.applications);
                }
            } catch (err) {
                setLoading(false);
                setTableData([]);
            }
        }
        getApplicationData();
    }, []);


    return (
        <Layout>
            <TopNavbar />
            <Content>
                <Layout>
                    <SideNavbar />
                    <Content style={{ padding: '0 24px', minHeight: 280, backgroundColor: '#FFFFFF' }}>
                        <Typography.Title level={1} style={{paddingTop: '4px', marginTop: '4px', marginBottom: '20px'}}>
                            applications
                        </Typography.Title>
                        
                        {/* <RecentApplicationsCard /> */}

                        <ApplicationsTable tableData={tableData} loading={loading} />

                        
                    </Content>
                </Layout>
            </Content>
            <PageFooter />
        </Layout>
    );
}

export default Applications