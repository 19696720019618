import { Modal, Table, Tag, Space } from "antd";
import { ColumnsType } from "antd/es/table";
import Column from "antd/es/table/Column";
import DataType from "../data_types/DataType";
import { useEffect, useState } from "react";
import { Link, Navigate } from "react-router-dom";

interface Props {
    tableData: DataType[];
    loading: boolean;
}

const ApplicationsTable = (props: Props) => {
    

    const showModal = (title: string, jobDescription: string) => {
        Modal.info({
            title: title,
            content: <>{jobDescription}</>,
            width:760
        })
    }

    const columns: ColumnsType<DataType> = [
        {
          title: 'Job Title',
          dataIndex: 'title',
          // specify the condition of filtering result
          // here is that finding the name started with `value`
          sorter: (a, b) => a.title && b.title ? a.title.localeCompare(b.title) : -1,
          sortDirections: ['descend', 'ascend'],
        },
        {
          title: 'Company',
          dataIndex: 'company',
          sortDirections: ['descend', 'ascend'],
          sorter: (a, b) => a.company && b.company ? a.company.localeCompare(b.company) : -1,
        },
        {
            title: "Job Description",
            dataIndex: "description",
            key: "description",
            ellipsis: true
        },
        {
            title: "Location",
            dataIndex: "location",
            key: "location",
            sorter: (a, b) => a.location && b.location ? a.location.localeCompare(b.location) : -1,
        },
        {
            title: "Date Applied",
            dataIndex: "dateApplied",
            key: "dateApplied",
            sorter: (a, b) => a.dateApplied && b.dateApplied ? (new Date(a.dateApplied) < new Date(b.dateApplied) ? 1 : -1) : -1,
        },
        {
            title: "Qualifications",
            dataIndex: "qualifications",
            key: "qualifications",
            sorter: (a, b) => a.qualifications && b.qualifications ? a.qualifications.localeCompare(b.qualifications) : -1,
            ellipsis: true
        },
        // {
        //     title: "Tags",
        //     dataIndex: "tags",
        //     key: "tags",
        //     render: (tags: string[]) => (
        //         <>
        //         {tags?.map((tag) => (
        //             <Tag color="blue" key={tag}>
        //                 {tag}
        //             </Tag>
        //         ))}
        //         </>
        //     )
        // },
        {
            title: "Action",
            key:"action",
            render: (_: any, record: DataType) => (
                <Space size="middle">
                    {/* <a onClick={async () => {showModal(record.title, record.description)}}>View Full Description</a> */}
                    {/* showModal(record.title, record.qualifications) */}
                    <Link to={`/applications/app`} state={ record }><a onClick={async () => {}}>View All Job Info</a></Link>
                    {/* <a>Delete</a> */}
                </Space>
            )
        },
        {
          title: 'Applied on',
          dataIndex: 'platform',
        //   filters: [
        //     {
        //       text: 'LinkedIn',
        //       value: 'LinkedIn',
        //     },
        //     {
        //       text: 'Tal',
        //       value: 'Tal',
        //     },
        //   ],
        },
      ];

    const [modal, contextHolder] = Modal.useModal();

    return (
        <Table columns={columns} dataSource={props.tableData} loading={props.loading} 
        // onRow={(record, rowIndex) => {
        //     return {
        //         onClick: event => {console.log(`clicked row ${rowIndex}`)}, // click row
        //     };
        // }}
        >
        </Table>
    )
}

export default ApplicationsTable