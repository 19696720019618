import React from 'react';
import { LogoutOutlined } from "@ant-design/icons";
import { Button, Menu, MenuProps } from "antd";
import Sider from "antd/es/layout/Sider";
import MenuItem from "antd/es/menu/MenuItem";
import { Link } from "react-router-dom";
import { HomeOutlined, FileOutlined, MenuFoldOutlined, MenuUnfoldOutlined} from '@ant-design/icons';

type MenuItem = Required<MenuProps>['items'][number];

const SideNavbar = () => {
    const [collapsed, setCollapsed] = React.useState(false);

    function getItem(
        label: React.ReactNode,
        key: React.Key,
        icon?: React.ReactNode,
        children?: MenuItem[],
        type?: 'group',
      ): MenuItem {
        return {
          key,
          icon,
          children,
          label,
          type,
        } as MenuItem;
      }
    
    function CollapseMenuButton() {
        return (
            <Button
                type="text"
                icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                onClick={() => setCollapsed(!collapsed)}
                size='large'
            />
        )
    }


    const sideNavbarItems : MenuProps['items'] = [
        getItem((<Link to={"/dashboard"}>dashboard</Link>), 'dashboard', <HomeOutlined/>),
        getItem(<Link to={"/applications"}>applications</Link>, 'applications', <FileOutlined/>), 
        {type: 'divider'},
        getItem((<Link to={"/logout"}>logout</Link>), 'Logout', <LogoutOutlined />)
      ]

    return (
        <Sider 
            width={200} 
            style={{height: '100vh'}}
            trigger={null} 
            breakpoint="lg" 
            collapsible 
            collapsed={collapsed} 
            onCollapse={(value) => setCollapsed(value)}
        >
            <div style={{backgroundColor: '#FFFFFF', alignContent: 'end', alignItems: 'end', textAlign: collapsed ? 'center' : 'end', paddingTop: '4px', paddingLeft: '4px', paddingRight: '4px', borderInlineEnd: '1px solid rgba(5,5,5,.06)'}}>
                {CollapseMenuButton()}  
            </div>
            
            <Menu 
                mode="inline"
                defaultSelectedKeys={[window.location.pathname.slice(1)]}
                defaultOpenKeys={['test']}
                style={{ height: '100%'}}
                items={sideNavbarItems}
            />
        </Sider>
    )
}

export default SideNavbar;