import { Menu, theme } from "antd";
import { Header } from "antd/es/layout/layout";
import { Link } from "react-router-dom";
// import '../styles/logo.css'
import logo_wide from "../images/trackr_wide.svg";


const TopNavbar = () => {
    const topNavbarItems = [
        
      ];

    // use for all pages since top navbar is in all

    // const {
    // token: { colorBgContainer },
    // } = theme.useToken();

    
    

    return (
        <Header style={{  alignItems: 'center', backgroundColor: '#FFFFFF', borderInlineEnd: '#eee',  paddingLeft: '20px', paddingRight: '20px', borderBottom: '1px solid rgba(5,5,5,.06)'}}>
            {/* <div className="logo-wide" /> */}
            <img src={logo_wide} style={{height: "60%", verticalAlign: 'middle'}} />
        </Header>
    )
}

export default TopNavbar;