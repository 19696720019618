import React from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Outlet,
  Navigate
} from "react-router-dom";

import './App.css';
import Login from './pages/Login';
import Logout from './pages/Logout';
import Dashboard from './pages/Dashboard';
import PrivateRoute from './components/PrivateRoute';
import { Button, ConfigProvider, theme } from 'antd';
import Applications from './pages/Applications';
import Index from './pages/Index';
import ApplicationView from './pages/ApplicationView';

function App() {

  const PrivateRoutes = () => {
    return (
      <>
        <PrivateRoute>
          <Outlet />
        </PrivateRoute>
      </>
    );
  }


  return (
    // TODO: add dark mode possibility
    // TODO: fix header during darkmode
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: '02b070',
          colorTextBase: '006641',
          // colorTextLightSolid: '02A342',
        },
        
        algorithm: theme.defaultAlgorithm,
      }}
    >
      <Router>
        <Routes>
          {/* <Route index element={ <Index /> } /> */}
          <Route path="/">
            <Route path="login" element={<Login />} />
            <Route path="/" element={ <PrivateRoutes /> }>
              <Route path="logout" element={ <Logout /> } />
              <Route path="dashboard" element={ <Dashboard /> } />
              <Route path="applications" element={ <Applications /> } />
              <Route path="applications/:application_id" element={ <ApplicationView /> } />
            </Route>
            <Route path="*" element={ <Navigate to="/dashboard" /> } />
          </Route>
          {/* <Route path="/" element={ <Index /> } /> */}
          <Route path="*" element={ <Navigate to="/dashboard" /> } />
        </Routes>
      </Router>
    </ConfigProvider>
  );
}

export default App;
