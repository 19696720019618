import React, { useEffect } from 'react';
import { base_url } from '../data_types/Constants';



const Logout = () => {
    useEffect(() => {
        const logout = async () => {
            await fetch(
                `${base_url}/login/logout`,
                {
                    method: "POST",
                    credentials: "include"
                }
            )
            window.location.href = "/login"
        }

        logout();
    }, []);

    return (
        <div>
            Logged outz
        </div>
    )
}

export default Logout