import { GoogleOAuthProvider } from '@react-oauth/google';
import { Button, Layout, Typography } from 'antd';
import React from 'react';
import GoogleLoginButton from '../components/GoogleLoginButton';
import TopNavbar from '../components/TopNavbar';
import { Content, Footer } from 'antd/es/layout/layout';
import PageFooter from '../components/PageFooter';

const Login = () => {
    return (
        <GoogleOAuthProvider clientId="574233132417-clb3hji8eps7es8p1qiqu4ul3j8t998g.apps.googleusercontent.com">
            <Layout>
                <TopNavbar />
                <Content style={{ padding: '0 50px', backgroundColor: '#FFFFFF' }}>
                    <Layout style={{ padding: '24px 0', backgroundColor: '#FFFFFF' }}>
                        <Content style={{ padding: '0 24px', minHeight: 280, backgroundColor: '#FFFFFF' }}>
                            <Typography.Title level={1} style={{ textAlign: 'center', paddingTop: '4px', paddingBottom: '20px', marginTop: '4px', marginBottom: '4px'}}>
                                Welcome to trackr!
                            </Typography.Title>
                            
                            <GoogleLoginButton />
                        </Content>
                    </Layout>
                </Content>
                <PageFooter />
                
            </Layout>
        </GoogleOAuthProvider>
    );
}

export default Login