import React from "react";
import { GoogleLogin, useGoogleLogin } from "@react-oauth/google";
import { Button } from "antd";
import { base_url } from "../data_types/Constants";

const GoogleLoginButton = () => {
    const onSuccess = async (res : any) => {
        const idToken = res.credential;
        console.log(res);
        console.log("idtoken = " + idToken);

        try {
            await fetch(
                `${base_url}/login`,
                {
                    method: "POST",
                    headers: { Authorization: `Bearer ${idToken}` },
                    credentials: "include"
                }
            );
        } catch (err) {
            console.log(err);
        }

        window.location.href = "/dashboard"
    }

    const onSuccessCustom = async (token : any) => {
        const idToken = token.code;
        console.log(token);
        console.log(token.code);
        console.log("idtoken = " + idToken);

        try {
            await fetch(
                `${base_url}/login`,
                {
                    method: "POST",
                    headers: { Authorization: `Bearer ${idToken}` },
                    credentials: "include"
                }
            );
        } catch (err) {
            console.log(err);
        }

        window.location.href = "/dashboard"
    }

    const onFailure = () => {
        console.log("Authentication failed");
    }

    const login = useGoogleLogin({
        onSuccess: onSuccessCustom,
        onError: onFailure,
        flow: 'auth-code',
    });

    return (
        <div style={{textAlign: 'center'}}>   
            <Button onClick={() => login()}>
                Sign in with Google 🚀{' '}
            </Button>
            {/* <GoogleLogin
                onSuccess={onSuccess}
                onError={onFailure}
                theme="filled_black"
                shape="circle"
                useOneTap
            /> */}
        </div>
    )

}

export default GoogleLoginButton;