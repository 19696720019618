import { FormOutlined, TeamOutlined } from '@ant-design/icons';
import { Card, Layout, theme } from 'antd';
import { Col, Row, Statistic } from 'antd';
import { useEffect, useState } from 'react';
import { base_url } from '../data_types/Constants';

interface Props {
    weeklyApplicationCount: number
}

const DashboardStats = (props: Props) => {
    const [applicationCount, setApplicationCount] = useState(0);

    useEffect(() => {
        const getApplicationCount = async () => {
            try {
                const res = await fetch(
                    `${base_url}/applications/count`,
                    {
                        method: "GET",
                        credentials: "include"
                    }
                );
                const data = await res.json();
                console.log(`data: ${data}`);
                setApplicationCount(data.count);
            } catch(err) {
                console.log("err");
            }
        }

        getApplicationCount();
    }, []);

    return (
        <Row gutter={16} style={{paddingTop: '8px', marginBottom: '20px'}}>
            <Col span={4}>
                <Card bordered={false}>
                    <Statistic title="Weekly Goal" value={props.weeklyApplicationCount} suffix="/ 100" />
                </Card>
            </Col>
            <Col span={4}>
                <Card bordered={false}>
                    <Statistic title="Total Applications" value={applicationCount} prefix={<FormOutlined />} />
                </Card>
            </Col>
            <Col span={16}>
                {/* <Card bordered={false}>
                    <Statistic title="Contacts" value={105} prefix={<TeamOutlined />} />
                </Card> */}
            </Col>
        </Row>
    );
}

export default DashboardStats