import { App, Breadcrumb, Card, Col, Layout, Modal, Row, Space, Table, Tag, Typography, theme } from 'antd';
import { Content, Footer, Header } from 'antd/es/layout/layout';
import TopNavbar from '../components/TopNavbar';
import SideNavbar from '../components/SideNavbar';
import RecentApplicationsCard from '../components/RecentApplicationsCard';
import ApplicationsTable from '../components/ApplicationsTable';
import PageFooter from '../components/PageFooter';
import { useEffect, useState } from 'react';

import { Link, useLocation, useParams } from 'react-router-dom';
import DataType from '../data_types/DataType';
// import * as DOMPurify from 'dompurify';

const ApplicationView = () => {

    const location = useLocation();
    const record: DataType = location.state;
    const cleanQualifications = record.qualifications//DOMPurify.sanitize(record.qualifications);

    return (
        <Layout>
            <TopNavbar />
            <Content>
                <Layout>
                    <SideNavbar />
                    <Content style={{ padding: '0 24px', minHeight: 280, backgroundColor: '#FFFFFF' }}>
                    <Breadcrumb
                        items={[
                        {
                            title: <Link to={"/applications"}>Applications</Link>,
                        },
                        {
                            title: record.title,
                        },
                        ]}
                    />

                        <Typography.Title level={1} style={{paddingTop: '4px', marginTop: '4px', marginBottom: '20px'}}>
                            your application to {record.company}:
                        </Typography.Title>

                        <Card title={record.title} hoverable style={{ width: "100%" }}>
                            <p>{record.company} - {record.location}</p>
                            <p>Qualifications:</p>
                            <div dangerouslySetInnerHTML={{__html: `<ul>${cleanQualifications}</ul>`}} />
                        </Card>

                    </Content>
                </Layout>
            </Content>
            <PageFooter />
        </Layout>
    );
}

export default ApplicationView