import { Line } from "@ant-design/plots";
import { useEffect, useState } from "react";

interface Props {
    data: any;
}

const WeeklyApplicationGraph = (props: Props) => {
    const config = {
        data: props.data,
        padding: 'auto' as any,
        xField: 'Date',
        yField: 'Applications',
        xAxis: {
            // type: 'timeCat',
            tickCount: 5,
        },
    };

    return (
        <Line {...config} />        
    )
}

export default WeeklyApplicationGraph;